@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';

.container {
	background-color: $black;
	color: $white;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 4rem 1.5rem;

	gap: $spacing-md;

	text-align: center;
}

.hero_title {
	font-size: clamp(1.5rem, 7vw, 6rem) !important;
	line-height: 1 !important;
	background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 70%), linear-gradient(91deg, #fac78e -11.22%, #e6be8c 99.24%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;

	margin-top: 0.25em;
	margin-bottom: 1em;
}

.subheadline {
	max-width: 30ch;
}

.heroImage {
	opacity: 0.99;
	margin: -15% auto auto;

	max-width: 915px;

	display: block;
	width: 100%;
}
