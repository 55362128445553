@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: $white;
	color: $black;
	display: grid;
	grid-template-columns: 1fr 1fr;

	h2 {
		font-weight: 400;
		margin: 0px;
		font-size: 5.25rem;
		line-height: 110%;
	}

	align-items: center;
	padding: $spacing-xl $spacing-md $spacing-xxl;

	@include sm-down {
		display: flex;
		flex-direction: column-reverse;
		gap: 4rem;

		h2 {
			font-size: 3rem;
		}

		padding: $spacing-xl $spacing-md;
	}
}

.description {
	@include body1;
	@include sm-down {
		@include body3;
	}
	color: #a6a6a6;
	max-width: 490px;
}

.podImage {
	display: flex;
	justify-self: flex-end;
	padding: 0 6rem;
	@include md-down {
		padding: 0 2rem;
	}

	img {
		max-height: 80vh;
	}

	@include sm-down {
		padding: 0 1rem;

		img {
			max-height: min(25rem, 80vh);
		}
	}
}

.infobox {
	display: flex;
	flex-direction: column;
	padding-left: 2rem;

	gap: 1.5rem;
}
