@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: $black;
	color: $white;
	padding: $spacing-xl $spacing-md;
	@include sm-down {
		margin: 0;
		border-radius: 0;
	}
}

.content {
	display: grid;
	grid-template-columns: 4fr 3fr;
	max-width: 1123px;
	align-items: center;
	margin: auto;
	@include sm-down {
		display: flex;
		flex-direction: column;
	}
}

.title {
	@include headline3;
	font-size: 5.5rem;
	font-size: 88px;
	font-style: normal;
	font-weight: 400;
	line-height: 1.12;
	order: 1;
	@include sm-down {
		@include headline5;
	}
}

.gradient {
	background: linear-gradient(277deg, #6c69ff 5.56%, #c260ff 95.84%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.mobGridContainer {
	display: none;
	order: 4;
	@include sm-down {
		display: inline;
	}
}

.features {
	@include headline9;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	order: 5;
	@include sm-down {
		margin-top: 2rem;
	}
}

.feature {
	display: grid;
	grid-template-columns: 24px auto;
	gap: 1rem;
	align-items: center;
}

.leftPanel {
	display: flex;
	flex-direction: column;
	gap: $spacing-md;
}

.rightPanel {
	position: relative;
	@include sm-down {
		display: none;
	}
}

.description {
	@include headline7;
	@include md-down {
		@include headline8;
		color: #a6a6a6;
		max-width: 315px;
	}

	font-weight: 400;
	color: #717171;
	line-height: 1.3; /* 31.2px */
	letter-spacing: 0.015rem;
	max-width: 607px;
	order: 2;
}

.ctas {
	display: flex;
	flex-direction: row;
	gap: 2rem;
	order: 6;
	@include sm-down {
		order: 3;
	}
}

.ctaButton {
	span {
		@include body4;
	}
}

.learnMore {
	span {
		@include button2;
	}
	color: #5289fe;
}

.phone {
	max-width: 334.597px;
	opacity: 0.2;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	filter: blur(5px);
	margin: auto;
	z-index: 0;
}

.podGridDesktop {
	position: absolute;
	top: 50%;
	left: 50%;
	border-radius: 10px;
	transform: translate(-50%, -50%);
	z-index: 1;
}

.podGrid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 0.625rem;
	padding: 1rem;

	background: var(--Charcoal, #141414);
	border: 1.07px solid rgba(255, 255, 255, 0.1);
}

.podCell {
	padding: 1rem;
	border-radius: 8px;
	border: 2px solid rgba(255, 255, 255, 0.08);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 10rem;
}

.podCellSelected {
	padding: 1rem;
	border-radius: 8px;
	border: 2px solid var(--transparent-8, rgba(255, 255, 255, 0.08));
	background: rgba(255, 255, 255, 0.08);

	display: flex;
	flex-direction: column;
}

.podTitle {
	@include body5;
}

.podDescription {
	@include body6;
	color: #adb3c5;
}

.podImage {
	width: 65px;
	margin-bottom: 1.75rem;
}

.podImageSelected {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.podDotSelected {
	width: 0.5rem;
	height: 0.5rem;
	border-radius: $br-circle;
	background-color: #246aff;
}

.podPlus {
	width: 2rem;
	height: 2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: $br-circle;
	background: rgba(255, 255, 255, 0.08);
	margin-bottom: 1.75rem;
}
