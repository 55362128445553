@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

$br: $spacing-xx-small-desktop;
.container {
	background-color: $background-black;
	color: $text-white;

	@include section-padding;

	contain: paint;
}

.wrapper {
	@include max-width-center;
	isolation: isolate;
	position: relative;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	margin-bottom: 5rem;

	gap: 1.5rem;

	@include sm-down {
		flex-direction: column;
		justify-content: flex-start;
	}
}

.heading {
	max-width: 25ch;
}

.main_content {
	display: grid;
	grid-template-columns: 5fr 4fr;

	gap: 1.25rem;

	@include sm-down {
		grid-template-columns: 1fr;
	}
}

.image_wrapper {
	position: relative;

	min-height: 15rem;
}

.main_image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;

	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		@include md-up {
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 50%);
		}
		z-index: 1;
		border-radius: $br;
	}

	img {
		width: 100%;
		height: 100%;
		object-position: 50% 25%;
		border-radius: $br;
	}
}

.num_reviews {
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 10;

	padding: 1rem;

	@include sm-down {
		display: none;
	}

	color: $text-grey-light;

	em {
		color: $text-white;
		font-style: normal;
		font-size: 4.5rem;
		font-weight: 500;
		display: block;
		line-height: 1;
	}
}

.reviews_list {
	list-style: none;
	display: grid;
	gap: 1.25rem;
	margin: 0;

	li {
		padding: 28px;
		background-color: $background-subdued;
		border-radius: $br;
	}
}

.review {
}

.stars {
	margin-bottom: 1rem;

	svg {
		width: 1rem;
		height: 1rem;
	}
}

.review_quote {
	margin-bottom: 1rem;

	max-width: 50ch;
}

.review_date {
	color: $text-grey-light;
}

.moire1,
.moire2 {
	position: absolute;
	opacity: 0.5;
	z-index: -1;
}

.moire1 {
	top: 25%;
	right: -5rem;
	height: 14rem;
	width: 20rem;
}

.moire2 {
	bottom: -2%;
	left: -3%;

	width: 25rem;
	height: 13rem;

	@include sm-down {
		bottom: 10%;
		left: auto;
		right: -5rem;
	}
}

.awards_wrapper {
	margin-top: 1.25rem;
	background-color: $background-subdued;
	border-radius: $br;

	padding: 1.25rem 2.5rem;

	@include sm-down {
		padding: 1.25rem;
	}

	overflow-x: auto;
}

.award_separator {
	display: block;
	width: 1px;
	min-width: 1px;
	background-color: $neutral-subdued-2;
}

.awards_list {
	list-style: none;
	display: flex;
	justify-content: space-between;
	max-width: 80rem;

	gap: 1.5rem;
	margin: 0;
	margin-inline: auto;
}

.award_item {
	min-width: 16rem;
}
